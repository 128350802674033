<template>
	<div class="ns-brand">
		<!-- <el-carousel height="400px" v-loading="loadingAd">
			<el-carousel-item v-for="item in adList" :key="item.adv_id"><el-image :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)" /></el-carousel-item>
		</el-carousel> -->
		 
		<div class="ns-brand-box" v-loading="loading">
			<!-- <div>
				<div class="ns-brand-title-wrap ns-text-align">
					<p class="ns-brand-title">需求大厅</p>
					<img src="@/assets/images/goods/split.png" alt="" />
					<p class="ns-brand-en">Supply Hall</p>
				</div>
			</div> -->
			<div class="center_column col-xs-12 col-sm-12">
				<div class="uc-content-box order-view-box">
					<div class="box-hd">
						<h1 class="title">项目详情</h1>
						<div class="more clearfix">
							<h2 class="subtitle" style="float: left;width: 90%;padding-bottom: 30px;">编号：{{ demand.demand_no }}<span>
									<div class="order-status" v-if="demand.is_end==true&&demand.goods_state!=3">立即报名</div>
									<div class="order-status" v-else-if="demand.goods_state==1">正在对接</div>
									<div class="order-status" v-else-if="demand.goods_state==2">正在实施</div>
									<div class="order-status" v-if="demand.goods_state==3">项目完成</div>
								</span>
							</h2>
							<div class="actions" style="float: right;width: 10%;">
								<!-- 打开测试窗口按钮 -->
								<el-button class="order_btn" type="primary" icon="el-icon-plus" size="mini" @click="handleDialog(demand.goods_id,demand.goods_name)" v-if="demand.is_end==true&&demand.goods_state!=3">报名</el-button>
								<!-- 使用组件-->
								<testDialog title="提交反馈" v-if="openDialog" ref="testDialog" />
								<!-- <div class="order_btn" data-toggle="modal" data-target="#myModal">报价
								</div> -->
							</div>
						</div>
					</div>
					<hr style="clear: left;" />
					<div class="box-bd">
						<div class="uc-order-item uc-order-item-finish">
							<div class="order-detail">
								<div class="order-summary">
									<div class="order-status">{{ demand.goods_name }}</div>
								</div>

								<table class="order-items-table" style="margin-top:20px">
									<tbody>

										<tr>
											<td class="col col-thumb">
												<div class="figure figure-thumb">
													<a target="_blank" href="#">
														<img :src="$img(demand.goods_image)" width="100px"
															height="100px" alt="">
													</a>
												</div>
											</td>
										</tr>

									</tbody>
								</table>
							</div>
							<hr style="clear: left;"></hr>
							<div id="editAddr" class="order-detail-info">
								<h3>联系方式</h3>
								<table class="info-table">
									<tbody>
										<tr>
											<th>姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名：</th>
											<td>{{ demand.contacts_name }}</td>
										</tr>
										<tr>
											<th>联系电话：</th>
											<td>{{ demand.contacts_phone }}</td>
										</tr>
										<!-- <tr>
											<th>收货地址：</th>
											<td>{{ demand.address }}</td>
										</tr> -->
									</tbody>
								</table>
							</div>
							<div id="editAddr" class="order-detail-info">
								<!-- <table class="info-table">
									<tbody>
										<tr>
											<th>采购分类：</th>
											<td>{{ demand.category_name }}</td>
										</tr>
										<tr>
											<th>采购数量：</th>
											<td>{{ demand.goods_num }}/{{ demand.unit }}</td>
										</tr>
										<tr>
											<th>创建时间：</th>
											<td>{{ demand.create_time }}</td>
										</tr>
										<tr>
											<th>期望货源地：</th>
											<td><span class="num">{{ demand.goods_source }}</span></td>
										</tr>
										<tr>
											<th>商品描述：</th>
											<td><span class="num">{{ demand.goods_describe }}</span></td>
										</tr>
									</tbody>
								</table> -->
								<p>项目描述：</p>
								<p>{{ demand.goods_describe }}</p>
							</div>
							<div id="editAddr" class="order-detail-info" v-if="demand.goods_state==3">
								<h3>完成信息</h3>
								<table class="info-table">
									<tbody>
										<tr>
											<th>公司名称：</th>
											<td>{{ demand.member_name }}</td>
										</tr>
										<!-- <tr>
											<th>收货地址：</th>
											<td>{{ demand.address }}</td>
										</tr> -->
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
	<!-- 模态框 -->
	<div class="modal fade" id="myModal">
		<div class="modal-dialog">
			<div class="modal-content">

				<!-- 模态框头部 -->
				<div class="modal-header">
					<h4 class="modal-title">模态框头部</h4>
					<button type="button" class="close" data-dismiss="modal">&times;</button>
				</div>

				<!-- 模态框主体 -->
				<div class="modal-body">
					模态框内容..
				</div>

				<!-- 模态框底部 -->
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-dismiss="modal">关闭</button>
				</div>

			</div>
		</div>
	</div>
</template>
<script>
	import {SupplyDetail} from '@/api/goods/goods';
	import {mapGetters} from 'vuex';
	import {adList} from '@/api/website';
	import testDialog from "./supply_form";

	export default {
		name: 'supply',
		components: {testDialog},
		data: () => {
			return {
				id: 0,
				total: 0,
				currentPage: 1,
				pageSize: 20,
				demand: [],
				siteId: 0,
				loading: true,
				loadingAd: true,
				adList: [],
				openDialog: false
			};
		},
		created() {
			this.id = this.$route.query.id
			console.log(this.$route.query);
			// this.getAdList();
			this.getSupplyDetail(this.id);
		},
		computed: {
			...mapGetters(['defaultBrandImage'])
		},
		methods: {
			handlePageSizeChange(size) {
				this.pageSize = size;
				this.refresh();
			},
			handleCurrentPageChange(page) {
				this.currentPage = page;
				this.refresh();
			},
			refresh() {
				this.loading = true;
				this.getSupplyDetail();
			},
			handleDialog(demand_id,name) {
				this.openDialog = true;
				this.$nextTick(() => {
					console.log(demand_id);
					this.$refs.testDialog.init(demand_id,name);
				});
			},
			getSupplyDetail(id) {
				SupplyDetail({
						demand_id: id,
					})
					.then(res => {
						console.log(res);
						console.log(res.data);
						this.demand = res.data;
						this.total = res.data.count;
						this.loading = false;
					})
					.catch(err => {
						this.loading = false;
						this.$message.error(err.message);
					});
			},
			/**
			 * 图片加载失败
			 */
			imageError(index) {
				this.brandList[index].image_url = this.defaultBrandImage;
			},
			/* getAdList() {
				adList({ keyword: 'SUPPLY_DEMAND' })
					.then(res => {
						this.adList = res.data.adv_list;
						for (let i = 0; i < this.adList.length; i++) {
							if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url);
						}
						this.loadingAd = false;
					})
					.catch(err => {
						this.loadingAd = false;
					});
			} */
		}
	};
</script>
<style lang="scss" scoped>
	hr{
		border-color: gray;
	}
	
	.order-status {
		height: 30px;
		line-height: 30px;
		font-size: 20px;
	}
	.box-hd{
		padding-top: 20px;
		padding-bottom: 30px;
	}
	.order-detail {
		padding-top: 20px;
		padding-bottom: 30px;
	}

	.order-detail-info {
		padding-top: 20px;
		padding-bottom: 30px;
		
		border-bottom: 1px solid gray;
	}

	.order-summary {
		font: 9.33px Microsoft YaHei, "宋体", Tahoma, Helvetica, Arial, "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
	}

	.order_btn {
		float: right;
		background-color: orange;
		padding-left: 15px;
		padding-right: 15px;
		top: -20px;
	}

	.info-table {
		text-align: left;
	}

	.supply_th_tr {
		height: 40px;
		width: 100%;
		padding-top: 5px;
		border-bottom: 1px solid #cecbcb;
	}

	.supply_th_tr th {
		width: 10%;
		line-height: 30px;
		text-align: left;
	}

	.supply_tr {
		height: 50px;
		width: 100%;
		padding-top: 5px;
		border-bottom: 1px solid #cecbcb;
	}

	.supply_td {
		width: 10%;
		line-height: 30px;
		text-align: left;
	}

	.ns-text-align {
		text-align: center;
	}

	.ns-brand-box {
		width: 100%;
		background: #ffffff;

		>div {
			width: $width;
			margin: 0 auto;
		}
	}

	.ns-brand-title-wrap {
		padding-top: 54px;

		.ns-brand-title {
			font-size: 26px;
			font-weight: 600;
			line-height: 30px;
		}

		.ns-brand-en {
			font-size: 24px;
			font-weight: 600;
			color: #383838;
			opacity: 0.2;
			text-transform: uppercase;
			letter-spacing: 5px;
			line-height: 30px;
		}
	}

	.ns-brand-list {
		display: flex;
		flex-wrap: wrap;
		padding-top: 30px;

		.ns-brand-li {
			width: 20%;
			padding: 8px 6px;
			box-sizing: border-box;

			.ns-brand-wrap {
				width: 100%;
				border: 1px solid #f1f1f1;
				overflow: hidden;
				color: #303133;
				padding: 15px;
				box-sizing: border-box;
				cursor: pointer;
				text-align: center;

				.el-image {
					width: 100%;
					height: 120px;
					line-height: 120px;
				}

				p {
					font-size: 22px;
					color: #383838;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
		}
	}

	.empty-wrap {
		margin-top: 30px;
	}
</style>
<style lang="scss">
	.ns-brand {
		.el-carousel {
			.el-image__inner {
				width: auto;
			}
		}

		.el-carousel__arrow--right {
			right: 60px;
		}
	}
</style>
